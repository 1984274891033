/* eslint-disable no-console */
import { takeLatest, select, call, put } from 'redux-saga/effects';
import { OrderActionType } from './OrderActionType';
import {
    createOrderSuccessAction,
    createOrderErrorAction,
    CreateOrderSuccessAction,
    CreateOrderAction,
    CreateOrderLinesSuccessAction,
    createOrderLinesSuccessAction,
    CreateDigitalOrderAction,
    createDigitalOrderSuccessAction,
    CreateDigitalOrderSuccessAction,
    createDigitalOrderLineSuccessAction,
    CreateDigitalOrderLineSuccessAction,
} from './OrderActions';
import { callApi, RequestConfig } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { FrontUser } from '../../domain/user';
import { AppState } from '../../store/reducers';
import { AxiosResponse } from 'axios';
import { Order, CartEntry, OrderLine } from '../../domain/commerce';
import { createClearCartAction, createOrderedRecentlyErrorAction, hasOrderedProductThePast30Days } from '../cart';
import pick from 'lodash/pick';
import map from 'lodash/map';
import dayjs from 'dayjs';
import { Router } from '../../i18n';
import {
    createToggleDigitalOrderModalAction,
    createToggleNotEnoughCreditsErrorModalAction,
    createToggleOrderModalAction,
} from '../UI';

function* createOrder(action: CreateOrderAction): SagaIterator {
    const user: FrontUser | undefined = yield select((state: AppState): FrontUser | undefined => state.profile.data);
    if (!user) {
        // console.error('No user found, aborting'); // FIXME: Do not use console, but a proper logging lib.
        return;
    }

    yield call(callApi, {
        endpoint: {
            url: 'users/orders',
            method: 'POST',
            body: {
                user: pick(user, 'id'),
                status: 'pending',
                orderedAt: dayjs().toISOString(),
                eventDetails: action.eventDetails,
                shippingDetails: action.shippingDetails,
            },
        },
        onSuccess: (res: AxiosResponse<Order>): CreateOrderSuccessAction => createOrderSuccessAction(res.data),
        onError: createOrderErrorAction,
    });
}

function* createDigitalOrder(action: CreateDigitalOrderAction): SagaIterator {
    const { videoProduct } = action;

    const user: FrontUser | undefined = yield select((state: AppState): FrontUser | undefined => state.profile.data);
    if (!user) {
        return;
    }

    const hasOrderedProductRecently = yield call(hasOrderedProductThePast30Days, videoProduct);

    if (!hasOrderedProductRecently) {
        yield call(callApi, {
            endpoint: {
                url: 'users/orders',
                method: 'POST',
                body: {
                    user: pick(user, 'id'),
                    status: 'pending',
                    orderedAt: dayjs().toISOString(),
                },
            },
            onSuccess: (res: AxiosResponse<Order>): CreateOrderSuccessAction =>
                createDigitalOrderSuccessAction(res.data, videoProduct),
            onError: createOrderErrorAction,
        });
    } else {
        yield put(createOrderedRecentlyErrorAction({ videoProduct }));
    }
}

function* addDigitalOrderLine(action: CreateDigitalOrderSuccessAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `orders/${action.order.id}/lines`,
            method: 'POST',
            body: {
                order: pick(action.order, 'id'),
                videoProduct: pick(action.videoProduct, 'id'),
                quantity: 1,
            },
        },
        onSuccess: (response: AxiosResponse<OrderLine>): CreateDigitalOrderLineSuccessAction => {
            return createDigitalOrderLineSuccessAction(response.data);
        },
        onError: createOrderErrorAction,
    });
}

function* addOrderLines(action: CreateOrderSuccessAction): SagaIterator {
    const cartItems: CartEntry[] = yield select((state: AppState): CartEntry[] => state.cart.items);

    yield call(callApi, {
        endpoint: cartItems.map(
            (item: CartEntry): RequestConfig => ({
                url: `orders/${action.order.id}/lines`,
                method: 'POST',
                body: {
                    order: pick(action.order, 'id'),
                    videoProduct: pick(item.videoProduct, 'id'),
                    quantity: item.quantity,
                },
            })
        ),
        onSuccess: (...responses: AxiosResponse<OrderLine>[]): CreateOrderLinesSuccessAction => {
            return createOrderLinesSuccessAction(map(responses, 'data'));
        },
        onError: createOrderErrorAction,
    });
}

function* clearCartAndDisplayConfirmation(): SagaIterator {
    yield call(Router.push, '/orderconfirmation');
    yield put(createClearCartAction());
}

function* hideOrderModalIfOpen(): SagaIterator {
    if (yield select((state: AppState): boolean => state.UI.orderModalIsOpen)) {
        yield put(createToggleOrderModalAction());
    }
}

function* displayDigitalOrderModal(): SagaIterator {
    yield call(hideOrderModalIfOpen);
    yield put(createToggleDigitalOrderModalAction());
}

function* handleCreateError(): SagaIterator {
    yield put(createToggleNotEnoughCreditsErrorModalAction());
}

export default function* orderSaga(): SagaIterator {
    yield takeLatest(OrderActionType.CREATE, createOrder);
    yield takeLatest(OrderActionType.CREATE_SUCCESS, addOrderLines);

    yield takeLatest(OrderActionType.CREATE_DIGITAL, createDigitalOrder);
    yield takeLatest(OrderActionType.CREATE_DIGITAL_SUCCESS, addDigitalOrderLine);

    yield takeLatest(OrderActionType.CREATE_LINES_SUCCESS, clearCartAndDisplayConfirmation);
    yield takeLatest(OrderActionType.CREATE_DIGITAL_LINE_SUCCESS, displayDigitalOrderModal);

    yield takeLatest(OrderActionType.CREATE_ERROR, handleCreateError);
}
