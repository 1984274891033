export enum OrderActionType {
    CREATE = 'ORDER/CREATE',
    CREATE_DIGITAL = 'ORDER/CREATE_DIGITAL',

    CREATE_DIGITAL_SUCCESS = 'ORDER/CREATE_DIGITAL_SUCCESS',
    CREATE_SUCCESS = 'ORDER/CREATE_SUCCESS',

    CREATE_ERROR = 'ORDER/CREATE_ERROR',

    ADD_ORDER_LINE = 'ORDER/ADD_ORDER_LINE',

    CREATE_LINES_SUCCESS = 'ORDER/CREATE_LINES_SUCCESS',
    CREATE_DIGITAL_LINE_SUCCESS = 'ORDER/CREATE_DIGITAL_LINE_SUCCESS',

    CLEAR_ERROR = 'ORDER/CLEAR_ERROR',
}
