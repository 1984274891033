/* eslint-disable react/no-deprecated */
import isFunction from 'lodash/isFunction';
import uniqueId from 'lodash/uniqueId';
import findIndex from 'lodash/findIndex';
import classNames from 'clsx';
import React, { Component } from 'react';
import EventKeys from '../../../EventKeys';
import AriaRole from '../../../AriaRole';
import { SVGRadioButtonIcon } from '../../SVGIcons';
import { WithT } from 'i18next';

/**
 * Radiogroup is a container which manage a list of RadioLabels.
 * Follows guidelines at https://www.w3.org/TR/wai-aria-practices-1.1/#radiobutton
 */
export interface RadioGroupProps<V> extends WithT {
    /**
     * Aria label to be passed in to the RadioGroup element. It overwrites the aria-label
     * passed through label.
     */
    ariaLabel?: string;

    /**
     * The label for the radio GROUP.
     * If ariaLabel is not passed, the label is added on the aria-label field.
     */
    label?: string;

    entries: RadioGroupEntry<V>[];

    showHorizontal?: boolean;

    onValueChanged?: (value: V) => void;

    className?: string;

    /**
     * If set to true, the value cannot be changed.
     * Defaults to false.
     */
    readonly?: boolean;

    /**
     * If set to true, no option will be selected by default (if no entry has `isSelected` set to true).
     */
    noOptionSelectedByDefault?: boolean;
}

export interface RadioGroupEntry<V> {
    /**
     * The displayed text for this entry (also the
     * aria-label)
     */
    label: string;

    /**
     * The value sent by onValueChanged callback.
     * (Not shown in the UI)
     */
    value: V;

    key?: string;

    /**
     * Whether or not the entry is initially selected.
     * Note that if several entries are selected, only the first
     * set as isSelected will actually be considered/shown as
     * selected.
     */
    isSelected?: boolean;

    /**
     * Allows to add extra markup into the RadioButton
     * if necessary
     */
    extraMarkup?: JSX.Element;
}

interface RadioGroupState {
    selectedIndex: number;
}

export class RadioGroup<V = unknown> extends Component<RadioGroupProps<V>, RadioGroupState> {
    private readonly radioGroupUniqueId: string = uniqueId('radio-group');
    private radioButtons: (HTMLElement | null)[] = [];

    public constructor(props: RadioGroupProps<V>) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
        this.onItemKeyDown = this.onItemKeyDown.bind(this);
        this.renderRadioItem = this.renderRadioItem.bind(this);

        this.state = {
            selectedIndex: this.getIndexOfSelectedItem(this.props.entries),
        };
    }

    public componentWillReceiveProps(nextProps: RadioGroupProps<V>): void {
        const indexToFocus: number = this.getIndexOfSelectedItem(nextProps.entries);
        if (indexToFocus !== this.state.selectedIndex) {
            this.setState({
                selectedIndex: indexToFocus,
            });
        }
    }

    private focusNewlySelectedItem(selectedIndex: number): void {
        const focusedEntry: HTMLElement | null = this.radioButtons[selectedIndex];
        if (focusedEntry != null) {
            focusedEntry.focus();
        }
    }

    private getIndexOfSelectedItem(entries: RadioGroupEntry<V>[]): number {
        const foundIndex = findIndex(entries, (entry: RadioGroupEntry<V>): boolean => {
            return !!entry.isSelected;
        });
        return this.props.noOptionSelectedByDefault ? foundIndex : Math.max(foundIndex, 0);
    }

    private onItemClick(e: React.SyntheticEvent, value: V): void {
        e.stopPropagation();
        if (this.props.readonly) {
            return;
        }
        const selectedIndex: number = this.findRadioIndex(value);
        this.setState({
            selectedIndex,
        });
        if (isFunction(this.props.onValueChanged)) {
            this.props.onValueChanged(value);
        }
        this.focusNewlySelectedItem(selectedIndex);
    }

    private onItemKeyDown(e: React.KeyboardEvent, value: V): void {
        if (this.props.readonly) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        switch (e.key) {
            case EventKeys.ENTER:
            case EventKeys.SPACE:
                e.stopPropagation();
                e.preventDefault();
                if (isFunction(this.props.onValueChanged)) {
                    this.props.onValueChanged(value);
                }
                break;
            case EventKeys.UP:
            case EventKeys.LEFT:
                e.stopPropagation();
                e.preventDefault();
                this.focusPreviousItem(value);
                break;
            case EventKeys.DOWN:
            case EventKeys.RIGHT:
                e.stopPropagation();
                e.preventDefault();
                this.focusNextItem(value);
                break;
            default:
                break;
        }
    }

    private focusNextItem(value: V): void {
        const currentIndex: number = this.findRadioIndex(value);
        const entriesSize: number = this.props.entries.length;
        const nextIndex: number = (currentIndex + 1) % entriesSize;
        this.setState({
            selectedIndex: nextIndex,
        });
        if (isFunction(this.props.onValueChanged)) {
            this.props.onValueChanged(this.props.entries[nextIndex].value);
        }
        this.focusNewlySelectedItem(nextIndex);
    }

    private focusPreviousItem(value: V): void {
        const currentIndex: number = this.findRadioIndex(value);
        const entriesSize: number = this.props.entries.length;
        const nextIndex: number = (currentIndex + entriesSize - 1) % entriesSize;
        this.setState({
            selectedIndex: nextIndex,
        });
        if (isFunction(this.props.onValueChanged)) {
            this.props.onValueChanged(this.props.entries[nextIndex].value);
        }
        this.focusNewlySelectedItem(nextIndex);
    }

    private findRadioIndex(value: V): number {
        return findIndex(this.props.entries, (entry: RadioGroupEntry<V>): boolean => {
            return entry.value === value;
        });
    }

    private renderRadioItem(entry: RadioGroupEntry<V>, index: number): JSX.Element {
        const isSelected: boolean | undefined = index === this.state.selectedIndex;
        const tabIndex: number = isSelected ? 0 : -1;
        const radioClassName: string = classNames('RadioGroup-List-Entry', {
            'RadioGroup-List-Entry-Vertical': !this.props.showHorizontal,
        });

        const onClick: (e: React.SyntheticEvent) => void = (e: React.SyntheticEvent): void => {
            this.onItemClick(e, entry.value);
        };

        const onKeyDown: (e: React.KeyboardEvent) => void = (e: React.KeyboardEvent): void => {
            this.onItemKeyDown(e, entry.value);
        };

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                aria-checked={isSelected}
                aria-label={entry.label}
                className={radioClassName}
                key={this.getUniqueKey(index)}
                onClick={onClick}
                onKeyDown={onKeyDown}
                ref={(e: HTMLElement | null): void => {
                    this.radioButtons[index] = e;
                }}
                role={AriaRole.RADIO}
                tabIndex={tabIndex}
            >
                <SVGRadioButtonIcon
                    className="RadioGroup-List-Entry-SVGIcon"
                    isSelected={isSelected}
                    t={this.props.t}
                />
                {entry.label}
                {entry.extraMarkup}
            </div>
        );
    }

    private getUniqueKey(index: number): string {
        return this.radioGroupUniqueId + '-' + index;
    }

    private renderGroupLabel(): JSX.Element | null {
        if (this.props.label == null) {
            return null;
        }
        return (
            <label className="RadioGroup-Label" htmlFor={this.radioGroupUniqueId}>
                {this.props.label}
            </label>
        );
    }

    public render(): JSX.Element {
        const className: string = classNames('RadioGroup-List', {
            'RadioGroup-List-Horizontal': this.props.showHorizontal,
            'RadioGroup-List-Vertical': !this.props.showHorizontal,
        });
        const ariaLabel: string | undefined = this.props.ariaLabel != null ? this.props.ariaLabel : this.props.label;
        return (
            <div className={classNames('RadioGroup', this.props.className)}>
                {this.renderGroupLabel()}
                <div
                    className={className}
                    id={this.radioGroupUniqueId}
                    role={AriaRole.RADIOGROUP}
                    aria-label={ariaLabel}
                >
                    {this.props.entries.map(this.renderRadioItem)}
                </div>
            </div>
        );
    }
}
