import { Action } from 'redux';
import { OrderActionType } from './OrderActionType';
import { Order, ShippingDetails, OrderLine, VideoProduct } from '../../domain/commerce';
import { RequestError } from '../../sagas/APICallSaga';
import { EventDetails } from '../../domain/manifestation';

export type OrderAction = Action<OrderActionType>;

export interface CreateOrderAction extends OrderAction {
    eventDetails?: EventDetails;
    shippingDetails?: ShippingDetails;
}

export interface CreateDigitalOrderAction extends OrderAction {
    videoProduct: VideoProduct;
}

export interface CreateDigitalOrderSuccessAction extends OrderAction {
    videoProduct: VideoProduct;
    order: Order;
}

export interface CreateOrderSuccessAction extends OrderAction {
    order: Order;
}
export interface CreateOrderErrorAction extends OrderAction {
    error: RequestError;
}

export interface CreateOrderLinesSuccessAction extends OrderAction {
    orderLines: OrderLine[];
}

export interface CreateDigitalOrderLineSuccessAction extends OrderAction {
    orderLine: OrderLine;
}

export function createOrderAction(eventDetails?: EventDetails, shippingDetails?: ShippingDetails): CreateOrderAction {
    return { type: OrderActionType.CREATE, eventDetails, shippingDetails };
}

export function createDigitalOrderAction(videoProduct: VideoProduct): CreateDigitalOrderAction {
    return { type: OrderActionType.CREATE_DIGITAL, videoProduct };
}

export function createOrderSuccessAction(order: Order): CreateOrderSuccessAction {
    return { type: OrderActionType.CREATE_SUCCESS, order };
}

export function createDigitalOrderSuccessAction(
    order: Order,
    videoProduct: VideoProduct
): CreateDigitalOrderSuccessAction {
    return { type: OrderActionType.CREATE_DIGITAL_SUCCESS, order, videoProduct };
}

export function createOrderErrorAction(error: RequestError): CreateOrderErrorAction {
    return { type: OrderActionType.CREATE_ERROR, error };
}

export function createOrderLinesSuccessAction(orderLines: OrderLine[]): CreateOrderLinesSuccessAction {
    return { type: OrderActionType.CREATE_LINES_SUCCESS, orderLines };
}

export function createDigitalOrderLineSuccessAction(orderLine: OrderLine): CreateDigitalOrderLineSuccessAction {
    return { type: OrderActionType.CREATE_DIGITAL_LINE_SUCCESS, orderLine };
}

export function createClearOrderErrorAction(): OrderAction {
    return { type: OrderActionType.CLEAR_ERROR };
}
