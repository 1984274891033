import {
    OrderAction,
    CreateOrderErrorAction,
    CreateOrderSuccessAction,
    CreateOrderLinesSuccessAction,
} from './OrderActions';
import { Order, OrderLine } from '../../domain/commerce';
import { OrderActionType } from './OrderActionType';
import { RequestError } from '../../sagas/APICallSaga';

export interface OrderState {
    placedOrder?: Order;
    placedOrderLines?: OrderLine[];
    error?: RequestError;
}

export const initialState: OrderState = {};

export const orderReducer = (state: OrderState = initialState, action: OrderAction): OrderState => {
    switch (action.type) {
        case OrderActionType.CREATE_SUCCESS:
            return {
                ...state,
                placedOrder: (action as CreateOrderSuccessAction).order,
            };

        case OrderActionType.CREATE_ERROR:
            return {
                ...state,
                error: (action as CreateOrderErrorAction).error,
            };

        case OrderActionType.CREATE_LINES_SUCCESS:
            return {
                ...state,
                error: undefined,
                placedOrderLines: (action as CreateOrderLinesSuccessAction).orderLines,
            };

        case OrderActionType.CLEAR_ERROR:
            return {
                ...state,
                error: undefined,
            };

        default:
            return state;
    }
};
